import 'promise-polyfill/src/polyfill';
import 'nos-forms-jquery';

$('#login-form').on('submit', function (e) {
    const req = $(this).find('*').filter('[required]');
    req.each(function () {
        if ($(this).val() === '') {
            e.preventDefault();
            const alert = $(this).parents('div.form-group').find('div.error-message');
            alert.removeClass('hidden');
            $(this).on('keyup', function () {
                if ($(this).val() !== '') {
                    alert.addClass('hidden');
                } else {
                    alert.removeClass('hidden');
                }
            });
        }
    });
});

// RESET PASSWORD
$('#newPassword').nosForm({
    fields: [
        {
            name: 'loginsid',
            type: 'hidden',
            value: $('#newPassword').data('loginsid'),
        },
        {
            name: 'password',
            type: 'password',
            required: true,
            minlength: 8,
            maxlength: 36,
            label: 'New Password',
        },
        {
            name: 'password2',
            type: 'password',
            required: true,
            minlength: 8,
            maxlength: 36,
            label: 'Repeat New Password',
            match: 'password',
            messages: {
                invalid: 'Your passwords must match!',
            },
        },
        {
            name: 'changePassword',
            type: 'submit',
            value: 'Update Password',
            formGroup: true,
            inline: true,
            classname: 'btn btn-primary',
        },
    ],
    ajax: false,
});
